import React from "react";

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// application
import PageHeader from "../shared/PageHeader";
// data stubs
import theme from '../../data/theme';

function SitePageCatalogs(){
    const breadcrumb = [
        { title: 'Главная', url: '/' },
        { title: 'Каталоги', url: '' },
    ];
    return(
        <React.Fragment>
             <Helmet>
                <title>{`Каталоги — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="Каталоги" breadcrumb={breadcrumb} />
            <div className="block faq">
                <div className="container">
                    <div className="faq__section">
                        <div className="faq__section-title">
                            <h3>FANAR</h3>
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-3">
                                    <div className="teammate__avatar">
                                        <Link to="/catalogs/fanar/FANAR_2021.pdf" target="_blank"><img src={"/catalogs/fanar/page-0001.jpg"} alt="no_img" /></Link>
                                    </div>
                                    <div className="typography">
                                    <Link to="/catalogs/fanar/FANAR_2021.pdf" target="_blank"><h6>Общий каталог FANAR 2021</h6></Link>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-3">
                                    <div className="teammate__avatar">
                                        <Link to="/catalogs/fanar/FANAR_2.pdf" target="_blank"><img src={"/catalogs/fanar/page-0001_2.jpg"} alt="no_img" /></Link>
                                    </div>
                                    <div className="typography">
                                    <Link to="/catalogs/fanar/FANAR_2.pdf" target="_blank"><h6>Каталог FANAR цельные твердосплавные фрезы</h6></Link>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-3">
                                    <div className="teammate__avatar">
                                        <Link to="/catalogs/fanar/FANAR_2021.pdf" target="_blank"><img src={"/catalogs/fanar/page-0001_3.jpg"} alt="no_img" /></Link>
                                    </div>
                                    <div className="typography">
                                        <Link to="/catalogs/fanar/FANAR_2021.pdf" target="_blank"><h6>Общий каталог FANAR 2018</h6></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}
export default SitePageCatalogs;