import axios from "axios";
import { ADD_PRODUCT } from "./productActionsTypes";
import { RESET_STATE } from "./productActionsTypes";

function addProduct(state, product){
    let newProduct = product
    newProduct.price.data = Number(newProduct.price.data)
    return {
        ...state,
        product: newProduct
    }
}


const initialState = {
    product: [],
}

export default function productReducer(state = initialState, action){
    switch(action.type){
        case ADD_PRODUCT:
            return addProduct(state, action.product);
        case RESET_STATE:
            return initialState;
        default:
            return state;
    }
}