import React, { Component } from "react";
import axios from "axios";

class CheckoutBuyer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            firstName: 'empty',
            lastName: 'empty',
            fullName: 'empty',
            email: 'empty',
            phone: 'empty',
            adress: 'empty',
            info: 'empty',
            inn: "",
        }
    }

    contactDataChange = (event) => {

        const target = event.target.id;
        const value = event.target.value
        switch (target) {
            case "checkout-first-name":
                this.setState({ firstName: value })
                break;
            case "checkout-last-name":
                this.setState({ lastName: value })
                break;
            case "checkout-email":
                this.setState({ email: value })
                break;
            case "checkout-phone":
                this.setState({ phone: value })
                break;
            case "checkout-comment":
                this.setState({ comment: value })
                break;
            case "checkout-inn":
                this.setState({ inn: value })
                break;
            default:
                break;
        }



    }
    createBuyer = () => {

        let buyer = {
            name: this.state.firstName + " " + this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            adress: this.state.adress,
            info: this.state.info,
            inn: this.state.inn,
        }
        axios.post("https://gateway.okibi-team.ru/sale-order-service/buyer", buyer)
            .then(response => {
                // this.setState({},() => this.props.onBuyerChange(response.data))
                buyer = {
                    ...buyer,
                    uuid: response.data,
                }
                this.setState({}, () => this.props.onBuyerChange(buyer))
            })
            .catch(err => {
                console.log(err)
            })

    }

    render() {
        return (
            <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0">
                    <div className="card-body">
                        <h3 className="card-title">Детали заказа</h3>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-first-name">Имя</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-first-name"
                                    placeholder="Иван"
                                    onChange={this.contactDataChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-last-name">Фамилия</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="checkout-last-name"
                                    placeholder="Иванов"
                                    onChange={this.contactDataChange}
                                />
                            </div>
                        </div>

                        {/* <div className="form-group">
                                            <label htmlFor="checkout-company-name">
                                                Название организации
                                                {' '}
                                                <span className="text-muted">(Необязательно)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-company-name"
                                                placeholder=""
                                            />
                                        </div> */}
                        {/* <div className="form-group">
                                            <label htmlFor="checkout-country">Страна</label>
                                            <select id="checkout-country" className="form-control">
                                                <option>Выберите страну</option>
                                                <option>США</option>
                                                <option>Россия</option>
                                                <option>Италия</option>
                                                <option>Франция</option>
                                                <option>Украина</option>
                                                <option>Германия</option>
                                                <option>Австралия</option>
                                            </select>
                                        </div> */}
                        {/* <div className="form-group">
                                            <label htmlFor="checkout-street-address">Улица</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder=""
                                            />
                                        </div> */}
                        {/* <div className="form-group">
                                            <label htmlFor="checkout-address">
                                                Дом, квартира...
                                                {' '}
                                                <span className="text-muted">(Необязательно)</span>
                                            </label>
                                            <input type="text" className="form-control" id="checkout-address" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Область</label>
                                            <input type="text" className="form-control" id="checkout-city" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-state">Город</label>
                                            <input type="text" className="form-control" id="checkout-state" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Почтовый индекс</label>
                                            <input type="text" className="form-control" id="checkout-postcode" />
                                        </div> */}

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-email">Ваша почта</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="checkout-email"
                                    placeholder="@mail"
                                    onChange={this.contactDataChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-phone">Ваш телефон</label>
                                <input type="text" className="form-control" id="checkout-phone" onChange={this.contactDataChange} placeholder="+7..." />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="checkout-email">ИНН <span className="text-muted">(Необязательно)</span></label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="checkout-inn"
                                    placeholder="ИНН"
                                    onChange={this.contactDataChange}
                                />
                            </div>
                            
                        </div>
 

                        {/* <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-create-account" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-create-account">
                                                    Создать аккаунт?
                                                </label>
                                            </div>
                                        </div> */}
                    </div>
                    <div className="card-divider" />
                    <div className="card-body">
                        <h3 className="card-title">Дополнительная информация</h3>

                        {/* <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-different-address" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-different-address">
                                                    Отправить по другому адресу?
                                                </label>
                                            </div>
                                        </div> */}

                        <div className="form-group">
                            <label htmlFor="checkout-comment">
                                Комментарий
                                {' '}
                                <span className="text-muted">(Необязательно)</span>
                            </label>
                            <textarea id="checkout-comment" className="form-control" onChange={this.contactDataChange} rows="4" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckoutBuyer