// react
import React from 'react';

// data stubs
import specification from '../../data/shopProductSpec';


function ProductTabSpecification(props) {
    
    let characteristics = props.characteristics;
    
    
    const sec = characteristics.list.map((feature, index) => (
        <div key={index} className="spec__row">
            <div className="spec__name">{feature.name}</div>
            <div className="spec__value">{feature.value}</div>
        </div>
    ));

    

    // const sections = specification.map((section, index) => {
    //     const features = section.features.map((feature, index) => (
    //         <div key={index} className="spec__row">
    //             <div className="spec__name">{feature.name}</div>
    //             <div className="spec__value">{feature.value}</div>
    //         </div>
    //     ));

    //     return (
    //         <div key={index} className="spec__section">
    //             <h4 className="spec__section-title">{section.name}</h4>
    //             {features}
    //         </div>
    //     );
    // });

    return (
        <div className="spec">
            <h3 className="spec__header">Характеристики</h3>
            <div  className="spec__section">
                <h4 className="spec__section-title">Основные</h4>
                {sec}
            </div>
            
            {/* <div className="spec__disclaimer">
                Information on technical characteristics, the delivery set, the country of
                manufacture and the appearance of the goods is for reference only and is based on
                the latest information available at the time of publication.
            </div> */}
        </div>
    );
}

ProductTabSpecification.defaultProps = {
    characteristics: {
        "list": [
          {
            "name": "",
            "value": ""
          },
          {
            "name": "",
            "value": ""
          }
        ]
      }
};

export default ProductTabSpecification;
