// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, Redirect, useHistory } from 'react-router-dom';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import CheckoutBuyer from '../shared/CheckoutBuyer';
// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import axios from 'axios';


class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);
        this.state = {
            payment: {
                payment_method_uuid: "00000000-0000-0000-0000-000000000002"
            },
            delivery: {
                delivery_method_uuid: "00000000-0000-0000-0000-000000000002"
            },
            firstName: 'empty',
            lastName: 'empty',
            email: 'empty',
            phone: 'empty',
            adress: 'empty',
            info: 'empty',
            comment: "empty",
            inn: "",
            buyer: {},
            errors: {},
        };
        this.validateValues = this.validateValues.bind(this);
    }


    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    contactDataChange = (event) => {
        const PATTERN = /\D/g;
        const PATTEN_N = /\d/g
        const target = event.target.id;
        const value = event.target.value
        switch (target) {
            case "checkout-first-name":
                let firstName = value.replace(PATTEN_N, "")
                event.target.value = firstName
                this.setState({ firstName: firstName })
                break;
            case "checkout-last-name":
                let lastName = value.replace(PATTEN_N, "")
                event.target.value = lastName
                this.setState({ lastName: lastName })
                break;
            case "checkout-email":
                this.setState({ email: value })
                break;
            case "checkout-phone":
                
                let phone = value.replace(PATTERN, "")
                event.target.value = phone
                this.setState({ phone: phone })
                break;
            case "checkout-comment":
                this.setState({ comment: value })
                break;
            case "checkout-inn":
                let inn = value.replace(PATTERN, "")
                event.target.value = inn
                this.setState({ inn: inn })
                break;
            default:
                break;
        }
    }

    sendOrderClicked = async () => {

        const fetchDataOkibi = (order) => {
            axios.post('https://gateway.okibi-team.ru/sale-order-service/order', order)
                .then(response => {
                    console.log(response)
                    if (response.status == 200) {
                        toast.success(`Ваша заявка успешно отправлена!`);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }

        const fetchDataMegaplan = (order) => {

            const items = this.props.cart.items

            let orderItems = []
            items.forEach(item => {
                let orderItem = {
                    product_uuid: item.product.uuid,
                    name: item.product.name,
                    product_price: {
                        price: item.price.data,
                        currency: "rub"
                    },
                    count: item.quantity
                }
                orderItems.push(orderItem)
            })

            axios.post('http://37.192.176.97:9097/', {
                site: "atv",
                phone: this.state.phone,
                name: this.state.firstName,

            })
                .then(response => {
                    console.log(response)
                    
                })
                .catch(err => {
                    console.log(err)
                })
        }

        const items = this.props.cart.items

        let orderItems = []
        items.forEach(item => {
            let orderItem = {
                product_uuid: item.product.uuid,
                name: item.product.name,
                product_price: {
                    price: item.price.data,
                    currency: "rub"
                },
                count: item.quantity
            }
            orderItems.push(orderItem)
        })

        let order = {
            idempotency_key: uuid(),
            delivery: this.state.delivery,
            payment: this.state.payment,
            order_items: orderItems,
            comment: this.state.comment,
            buyer: {
                name: this.state.firstName + " " + this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                adress: 'empty',
                info: 'empty',
                inn: this.state.inn,
            }
        }
        await this.validateValues().then(prom => { this.setState(() => ({ errors: prom })) })
        const { errors } = this.state
        if (Object.keys(errors).length === 0) {
            fetchDataOkibi(order)
            // fetchDataMegaplan()
            this.props.history.push('/shop/success-order')
        }


        // fetchData(order)

    }

    async validateValues() {
        let errors = {};
        const { firstName, lastName, email, phone } = this.state
        let prom = new Promise((resolve, reject) => {
            if (firstName === "empty" || firstName === "") {
                errors.firstName = "Введите имя"
            }
            if (lastName === "empty" || lastName === "") {
                errors.lastName = "Введите фамилию"
            }
            if (email === "empty" || email === "") {
                errors.email = "Введите почту"
                // /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w)$/
            }
            if (phone === "empty" || phone === "" || phone.length < 10) {
                errors.phone = "Введите номер телефона"
            }
            resolve(errors)
        })
        return await prom
    }

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={extraLine.price} /></td>
            </tr>
        ));

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Промежуточный итог</th>
                        <td><Currency value={cart.subtotal} /></td>
                    </tr>
                    {/* {extraLines} */}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart } = this.props;

        const items = cart.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td><Currency value={item.total} /></td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Товар</th>
                        <th>Итого</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">
                    {items}
                </tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Итого</th>
                        <td><Currency value={cart.total} /></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment } = this.state;

        const payments = this.payments.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">
                    {payments}
                </ul>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const { errors } = this.state
        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }
        const breadcrumb = [
            { title: 'Главная', url: '/' },
            { title: 'Корзина', url: '/shop/cart' },
            { title: 'Оставить заявку', url: '' },
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Оставить заявку — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Оставить заявку" breadcrumb={breadcrumb} />

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-12 mb-3">
                                <div className="alert alert-primary alert-lg">
                                    Уже зарегестрированы?
                                    {' '}
                                    <Link to="/account/login">Нажмите чтобы зайти в аккаунт</Link>
                                </div>
                            </div> */}

                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Детали заказа</h3>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-first-name">Имя</label>
                                                <input
                                                    type="text"
                                                    className={errors.firstName ? "form-control error_form" : "form-control"}
                                                    id="checkout-first-name"
                                                    placeholder="Иван"
                                                    onChange={this.contactDataChange}
                                                />
                                                {errors.firstName ? <p className="checkout__error"> {errors.firstName} </p> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-last-name">Фамилия</label>
                                                <input
                                                    type="text"
                                                    className={errors.lastName ? "form-control error_form" : "form-control"}
                                                    id="checkout-last-name"
                                                    placeholder="Иванов"
                                                    onChange={this.contactDataChange}
                                                />
                                                {errors.lastName ? <p className="checkout__error"> {errors.lastName}</p> : null}
                                            </div>
                                        </div>

                                        {/* <div className="form-group">
                                            <label htmlFor="checkout-company-name">
                                                Название организации
                                                {' '}
                                                <span className="text-muted">(Необязательно)</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-company-name"
                                                placeholder=""
                                            />
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label htmlFor="checkout-country">Страна</label>
                                            <select id="checkout-country" className="form-control">
                                                <option>Выберите страну</option>
                                                <option>США</option>
                                                <option>Россия</option>
                                                <option>Италия</option>
                                                <option>Франция</option>
                                                <option>Украина</option>
                                                <option>Германия</option>
                                                <option>Австралия</option>
                                            </select>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label htmlFor="checkout-street-address">Улица</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="checkout-street-address"
                                                placeholder=""
                                            />
                                        </div> */}
                                        {/* <div className="form-group">
                                            <label htmlFor="checkout-address">
                                                Дом, квартира...
                                                {' '}
                                                <span className="text-muted">(Необязательно)</span>
                                            </label>
                                            <input type="text" className="form-control" id="checkout-address" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-city">Область</label>
                                            <input type="text" className="form-control" id="checkout-city" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-state">Город</label>
                                            <input type="text" className="form-control" id="checkout-state" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="checkout-postcode">Почтовый индекс</label>
                                            <input type="text" className="form-control" id="checkout-postcode" />
                                        </div> */}

                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-email">Ваша почта</label>
                                                <input
                                                    type="email"
                                                    className={errors.email ? "form-control error_form" : "form-control"}
                                                    id="checkout-email"
                                                    placeholder="@mail"
                                                    onChange={this.contactDataChange}
                                                />
                                                {errors.email ? <p className="checkout__error"> {errors.email}</p> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-phone">Ваш телефон</label>
                                                <input type="text"
                                                    className={errors.phone ? "form-control error_form" : "form-control"}
                                                    id="checkout-phone"
                                                    onChange={this.contactDataChange}
                                                    maxLength="12"
                                                    placeholder="+7..."
                                                />
                                                {errors.phone ? <p className="checkout__error"> {errors.phone}</p> : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="checkout-email">ИНН <span className="text-muted">(Необязательно)</span></label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="checkout-inn"
                                                    placeholder="ИНН"
                                                    maxLength="12"
                                                    onChange={this.contactDataChange}
                                                />

                                            </div>

                                        </div>


                                        {/* <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-create-account" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-create-account">
                                                    Создать аккаунт?
                                                </label>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="card-divider" />
                                    <div className="card-body">
                                        <h3 className="card-title">Дополнительная информация</h3>

                                        {/* <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-different-address" />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-different-address">
                                                    Отправить по другому адресу?
                                                </label>
                                            </div>
                                        </div> */}

                                        <div className="form-group">
                                            <label htmlFor="checkout-comment">
                                                Комментарий
                                                {' '}
                                                <span className="text-muted">(Необязательно)</span>
                                            </label>
                                            <textarea id="checkout-comment" className="form-control" onChange={this.contactDataChange} rows="4" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <CheckoutBuyer
                                ref={instance => { this.checkoutBuyer = instance; }}
                                onBuyerChange={this.setBuyerUuid}
                            /> */}


                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Ваш заказ</h3>

                                        {this.renderCart()}

                                        {/* {this.renderPaymentsList()} */}

                                        <div className="checkout__agree form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input className="input-check__input" type="checkbox" id="checkout-terms" defaultChecked />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="checkout-terms">
                                                    Я согласен с политикой конфиденциальности
                                                    {/* <Link to="site/terms"> политикой конфиденциальности</Link>
                                                    * */}
                                                </label>
                                            </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-xl btn-block" onClick={this.sendOrderClicked}>Оставить заявку</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
