
import { ADD_PRODUCT } from "./productActionsTypes";
import axios from "axios";
export function addProduct(product){
    return {
        type: ADD_PRODUCT,
        product
    }
}

    export function getProduct(product_id){
        return dispatch => {
            return axios.post('https://gateway.okibi-team.ru/product/' + product_id,{
            }).then(res => {
                dispatch(addProduct(res.data.product))
            }).catch(err => {
                console.log(err)
            })
    }
    }


