// react
import React, {useState, useEffect} from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from 'axios';
// application
import Currency from '../shared/Currency';
import WidgetImage from './WidgetImage';

function WidgetProducts(props) {
    const { title, products } = props;


    const productsList = products.map((product) => {
        let image;
        let price;
        // fetchData(product)
        
        


        // if (typeof(imageData) !== 'undefined') {
        //     let imageBase64 = imageData.data
        //     image = (
        //         <div className="widget-products__image">
        //             <Link to="/"><img src={this.getImage()} alt="" /></Link>
        //         </div>
        //     );
        // }

        if (product.compareAtPrice) {
            price = (
                <React.Fragment>
                    <span className="widget-products__new-price"><Currency value={product.price.data} /></span>
                    {' '}
                    <span className="widget-products__old-price"><Currency value={product.compareAtPrice} /></span>
                </React.Fragment>
            );
        } else {
            price = <Currency value={product.price.data} />;
        }

        return (
            <div key={product.uuid} className="widget-products__item">
                <WidgetImage product={product} />
                <div className="widget-products__info">
                    <div className="widget-products__name">
                        <Link to={`/shop/product/${product.uuid}`}>{product.name}</Link>
                    </div>
                    <div className="widget-products__prices">
                        {/* {price} */}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="widget-products widget">
            <h4 className="widget__title">{title}</h4>
            <div className="widget-products__list">
                {productsList}
            </div>
        </div>
    );
}

WidgetProducts.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * array of product objects
     */
    products: PropTypes.array,
};

WidgetProducts.defaultProps = {
    products: [],
};

export default WidgetProducts;
