export default [
    {
        title: 'Расходные материалы',
        url: 'in/dev',
        subcategory: {
            type: 'menu',
            menu: [
                { title: 'Лазерные станки', url: 'in/dev', },
                { title: 'Электроэрозия', url: 'in/dev' },
                { title: 'Сварка', url: 'in/dev' },
                { title: 'Компрессорное оборудование', url: 'in/dev' },
                { title: 'Гидроабразив', url: 'in/dev' },
            ],
        },
    },
    {
        title: 'Гибочный инструмент',
        url: '',
        subcategory: {
            type: 'menu',
            menu: [
                { title: 'Amada Promecam system', url: 'in/dev', },
                { title: 'Wila Trumpf system', url: 'in/dev' },
            ],
        },
    },
    { title: 'Токарный инструмент', url: 'in/in/dev', submenu: null },
    { title: 'Фрезерный инструмент', url: 'in/dev', submenu: null },
    { title: 'Смазочные материалы', url: 'in/dev', submenu: null },
    { title: 'Штампы', url: 'in/dev', submenu: null },
    { title: 'Ленточные пилы', url: 'in/dev', submenu: null },
    { title: 'Криогенное оборудование', url: 'in/dev', submenu: null },
    { title: 'Для полимерных камер', url: 'in/dev', submenu: null },
    { title: 'Ножи для гильотины', url: 'in/dev', submenu: null },
    { title: 'Мерительный инструмент', url: 'in/dev', submenu: [] }
];


