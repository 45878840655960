import axios from 'axios';
import { CATEGORY_GET } from './categoryActionTypes';

export const getCategories = categories => {
    return {
      type: CATEGORY_GET,
      payload: categories
    }
  }


export const categoryGetItem =  () => {
    return dispatch => {
        
        return axios.get('https://gateway.okibi-team.ru/category',{
            responseType: 'json',
        }).then((res) => {
            dispatch(getCategories(res.data.category_list));
        })
    }
}


