// application
import enMessages from './messages/en';
import ruMessages from './messages/ru';
import frMessages from './messages/fr';


export default {
    en: enMessages,
    ru: ruMessages,
    fr: frMessages,
};
