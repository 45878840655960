import React, {useEffect, useState} from "react"


// third-party
import { Link } from 'react-router-dom';
import axios from "axios";

function WidgetImage(props){
    const {product} = props
    const [imageData, setimageData] = useState([])
    let image;
    useEffect(()=>{
        let cleanupFunction = false;

        const fetchData = async () => {
            try {
                const response = await axios.get("https://filestorage.okibi-team.ru/file_storage/" + product.miniature.file_uuid);
                if (!cleanupFunction) setimageData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
          };

        fetchData();

        return () => cleanupFunction = true;
    },[])

    if (imageData.data) {
        let imageBase64 = imageData.data
        image = (
            <Link to={`/shop/product/${product.uuid}`}><img src={`data:image/jpeg;base64,${imageBase64}`} alt="" /></Link>
        )
    }

    return (
        <div className="widget-products__image">
            {image}
        </div>
    )
}

export default WidgetImage;