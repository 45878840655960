import React, { Component } from "react";
// third-party

import { Modal, ModalHeader } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import uuid from 'react-uuid';
import axios from "axios";

import { Cross20Svg } from '../../svg';


class Feedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: 'empty',
            phone: 'empty',
            email: 'empty',
            comment: 'empty',
            showOrderAddSuccess: false,
            files: null,
            filesArray: [],
            errors: {},
            payment: {
                payment_method_uuid: "00000000-0000-0000-0000-000000000002"
            },
            delivery: {
                delivery_method_uuid: "00000000-0000-0000-0000-000000000002"
            },
        }
    }
    async validateValues() {
        let errors = {};
        const { name, email, phone } = this.state
        let prom = new Promise((resolve, reject) => {
            if (name === "empty" || name === "") {
                errors.name = "Введите имя"
            }
            if (email === "empty" || email === "") {
                errors.email = "Введите почту"
            }
            if (phone === "empty" || phone === "") {
                errors.phone = "Введите номер телефона"
            }
            resolve(errors)
        })
        return await prom
    }
    contactDataChange = (event) => {

        const target = event.target.id;
        const value = event.target.value
        switch (target) {
            case "feedback-name":
                this.setState({ name: value })
                break;
            case "feedback-email":
                this.setState({ email: value })
                break;
            case "feedback-phone":
                const PATTERN = /\D/g;
                let phone = value.replace(PATTERN, "")
                event.target.value = phone
                this.setState({ phone: phone })
                break;
            case "feedback-comment":
                this.setState({ comment: value })
                break;
            case "feedback-file":
                this.setState({ files: event.target.files })
            default:
                break;
        }
    }


    async fileStorageRequest(element) {
        if (this.state.files !== null) {
            let formData = new FormData()
            formData.append('file', element)
            const res = await axios.post("https://gateway.okibi-team.ru/sale-order-service/file",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            )
            return await res.data.uuid
        }
    }
    async createOrder(order) {
        const res = axios.post('https://gateway.okibi-team.ru/sale-order-service/order', order)
        return await res
    }
    async sendFiles() {

        const promise = new Promise((resolve, reject) => {
            
            Array.from(this.state.files).forEach(element => {
                this.fileStorageRequest(element).then(data => this.setState({ filesArray: this.state.filesArray.concat(data) },
                    () => {
                        if (this.state.files.length == this.state.filesArray.length) {
                            resolve()
                        }
                    }
                ))
            })

        })
        return await promise;
    }
    async sendOrder() {

        const fetchDataMegaplan = (order) => {

            axios.post('https://handler.sibmetall-nsk.ru/index.php', {
                site: "atv",
                phone: this.state.phone,
                name: this.state.firstName,

            })
                .then(response => {
                    console.log(response)

                })
                .catch(err => {
                    console.log(err)
                })
        }

        await this.validateValues().then(prom => { this.setState(() => ({ errors: prom })) })
        const { errors } = this.state
        if (Object.keys(errors).length === 0) {
            if (this.state.files !== null) {
                await this.sendFiles()
            }

            let order = {
                idempotency_key: uuid(),
                delivery: this.state.delivery,
                payment: this.state.payment,
                order_items: [],
                comment: this.state.comment,
                file_info_ids: this.state.filesArray,
                buyer: {
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    adress: 'empty',
                    info: 'empty',
                    inn: ""
                }
            }
            fetchDataMegaplan()
            // await this.createOrder(order).then(response => {
            //     if (response.status == 200) {
            //         this.setState({ showOrderAddSuccess: true })
            //         setTimeout(() => {
            //             this.setState({ showOrderAddSuccess: false })
            //             this.setState({ filesArray: [] })
            //             this.setState({ files: null })
            //         }, 8000)
            //     }
            // })
        }
    }


    render() {
        const { open, toogleModal } = this.props
        const { errors } = this.state
        if (this.state.showOrderAddSuccess) {
            return <Redirect to="/shop/success-order" />;
        }
        return (
            <Modal isOpen={open} toggle={toogleModal}>
                {/* <ModalHeader toggle={toogle}>Modal title</ModalHeader> */}
                {this.state.showOrderAddSuccess ? <div className="feedback_result">
                    <button className="quickview__close" type="button" onClick={toogleModal}>
                        <Cross20Svg />
                    </button>
                    <img className="succes_feedback_img" src="images/ico/success.png" alt="" />
                    <h2 className="feedback_title">Ваша заявка отправлена</h2>
                    <p>В скором времени с вами свяжется наш специалист</p>

                </div>
                    :
                    <div className="feedback">
                        <button className="quickview__close" type="button" onClick={toogleModal}>
                            <Cross20Svg />
                        </button>
                        <div className="feedback_title">
                            <h2 >Обратный звонок</h2>
                            <p>Оставьте контактные данные и в скором времени с вами свяжется наш специалист</p>
                        </div>

                        <div className="form">
                            <div className="form-group">
                                <label htmlFor="checkout-first-name">Имя</label>
                                <input
                                    type="text"
                                    className={errors.name ? "form-control error_form" : "form-control"}
                                    id="feedback-name"
                                    placeholder="Иван"
                                    onChange={this.contactDataChange}
                                />
                                {errors.name ? <p className="checkout__error"> {errors.name} </p> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-first-name">Телефон</label>
                                <input
                                    type="text"
                                    className={errors.phone ? "form-control error_form" : "form-control"}
                                    id="feedback-phone"
                                    placeholder="+7..."
                                    onChange={this.contactDataChange}
                                />
                                {errors.phone ? <p className="checkout__error"> {errors.phone} </p> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-first-name">Email</label>
                                <input
                                    type="text"
                                    className={errors.email ? "form-control error_form" : "form-control"}
                                    id="feedback-email"
                                    placeholder="example@mail.ru"
                                    onChange={this.contactDataChange}
                                />
                                {errors.email ? <p className="checkout__error"> {errors.email}</p> : null}
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-comment">
                                    Комментарий
                                    {' '}
                                    <span className="text-muted">(Необязательно)</span>
                                </label>
                                <textarea
                                    id="feedback-comment"
                                    className="form-control"
                                    onChange={this.contactDataChange}
                                    rows="4" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-file">
                                    Прикрепите файл
                                </label>
                                <input
                                    type="file"
                                    // className="form-control"
                                    id="feedback-file"
                                    onChange={this.contactDataChange}
                                    multiple
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary btn-xl btn-block"
                                // onClick={this.createBuyer}
                                // onClick={this.sendOrder}
                                onClick={() => this.sendOrder()}
                            >Оставить заявку</button>
                        </div>
                    </div>}
            </Modal>
        )
    }
}
export default Feedback;