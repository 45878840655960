// react
import React, { useEffect } from 'react';

// third-party
import classNames from 'classnames';
import { connect, useSelector, useDispatch } from 'react-redux';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose, mobileMenuGetLinks } from '../../store/mobile-menu';
// data stubs
import currencies from '../../data/shopCurrencies';
import mobileMenuLinks from '../../data/mobileMenu';


function MobileMenu(props) {
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
    } = props;

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const dispatch = useDispatch()
    const mobileMenuLinks = useSelector(state => state.mobileMenu.links)
    
    async function getLinks() {
        await dispatch(mobileMenuGetLinks())
    }

    useEffect(() => {
        getLinks()
    }, [])


    const handleItemClick = (item) => {
        
        if (item.type) {
            if (item.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.type === 'link') {
                console.log("click:" + item.type)
                closeMobileMenu();
            }
            if (item.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Меню</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
