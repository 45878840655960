import { CATEGORY_GET } from './categoryActionTypes';
import departmentsLinksArray from '../../data/headerDepartments';
import { element } from 'prop-types';


function getDepLinks(categories){

    categories.forEach(element => {
        let child_list = element.child_list;
        let category = departmentsLinksArray.find(x => x.title === element.name);
        if (typeof category !== 'undefined'){
            category.url = element.category_uuid
            if (typeof category['subcategory'] !== 'undefined' ){
                category.subcategory.menu.forEach(element => {
                
                    let child_category = child_list.find(x => x.name === element.title);
                    if (typeof child_category !== 'undefined'){
                        element.url = child_category.category_uuid;
                    }
                })
            }
            
        }
    });
    return departmentsLinksArray;
}


export default function categoryReducer(state = [], action) {
    switch (action.type) {
        case CATEGORY_GET:
            return getDepLinks(action.payload)
        default:
            return state;
    }
}
