import { ADD_PRODUCTS, ADD_POPULAR_PRODUCTS } from './shopActionsTypes';
import { ADD_FILTERS } from './shopActionsTypes';
import { ADD_IMAGES } from './shopActionsTypes';
import axios from 'axios';

export function addProducts(products) {
    return {
        type: ADD_PRODUCTS,
        products,
    };
}

export function addFilters(category) {
    return {
        type: ADD_FILTERS,
        category
    }
}

export function addPopularProducts(products) {
    return {
        type: ADD_POPULAR_PRODUCTS,
        products
    }
}

export function getPopularProducts(category_id) {
    return dispatch => {
        axios.get("https://gateway.okibi-team.ru/product?category=" + category_id)
            .then(response => {
                dispatch(addPopularProducts(response.data.product_list))

            }).catch(err => {
                console.log(err);
            })
    }
}

export function chandgeCategory(category_id) {

    return dispatch => {
        // axios.post('https://gateway.okibi-team.ru/products', {
        //     category_id: category_id
        // }).then(response => {

        //     dispatch(addProducts(response.data.product_list))

        // }).catch(err => {
        //     console.log(err);
        // })
        axios.get("https://gateway.okibi-team.ru/product?category=" + category_id)
            .then(response => {
                dispatch(addProducts(response.data.product_list))
            })
        axios.get('https://gateway.okibi-team.ru/category/' + category_id, {
            responseType: 'json'
        }).then(response => {
            dispatch(addFilters(response.data.category_list[0]))
        })

    }


}

