// react
import React, { useState, useEffect } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// application
import Menu from './Menu';
import Megamenu from './Megamenu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

import { categoryGetItem } from '../../store/category';


function DepartmentsLinks(props) {
    const {
        buttonLabel,
        className
    } = props;

    const dispatch = useDispatch()
    const categories = useSelector(state => state.category)
    

    useEffect(() => {
        getCategories()
    }, [])


    async function getCategories() {
        await dispatch(categoryGetItem())
    }
    const handleMouseEnter = (event) => {
        const item = event.currentTarget;
        const megamenu = item.querySelector('.departments__megamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;
            const megamenuPosition = Math.round(
                Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
            );

            megamenu.style.left = `${megamenuPosition}px`;
        }
    };
  
    const linksList = categories.map((category, index) => {

        let arrow;
        let submenu;
        let itemClass = '';

        if (category.subcategory && category.subcategory.length != 0) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        if (category.subcategory && category.subcategory.length != 0) {
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <Menu items={category.subcategory.menu} />
                </div>
            );
        }

        if (category.subcategory && category.subcategory.type === 'megamenu') {
            submenu = (
                <div className={`departments__megamenu departments__megamenu--${category.subcategory.menu.size}`}>
                    <Megamenu menu={category.subcategory.menu} />
                </div>
            );
        }

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={`/shop/category/${category.url}`}>
                    {category.title}
                    {arrow}
                </Link>
                 {submenu} 
            </li>
        );
    });

    return (
        <ul className="departments__links">
            {linksList}
        </ul>
    );
}





export default DepartmentsLinks
