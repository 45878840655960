export default {
    name: 'TSTTOOLS',
    fullName: 'TSTTOOLS - Магазин инструмента',
    url: '',
    author: {
        name: '',
        profile_url: '',
    },
    contacts: {
        address: 'г. Новосибирск, советское шоссе 19/2',
        email: 'info@tsttools.ru',
        phone: '+7 (499) 322-22-56',
    },
};
