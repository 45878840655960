// react
import React, { useState, useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { connect, useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
// blocks
import BlockBanner from '../blocks/BlockBanner';
import BlockCategories from '../blocks/BlockCategories';
import BlockFeatures from '../blocks/BlockFeatures';
import BlockPosts from '../blocks/BlockPosts';
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockProducts from '../blocks/BlockProducts';
import BlockSlideShow from '../blocks/BlockSlideShow';
import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';


// data stubs
import categories from '../../data/shopBlockCategories';
import posts from '../../data/blogPosts';
// import products from '../../data/shopProducts';
import { getPopularProducts } from '../../store/shop';
import { categoryGetItem } from '../../store/category';

function HomePageOne() {
    // const columns = [
    //     {
    //         title: 'Лучший рейтинг',
    //         products: products.slice(0, 3),
    //     },
    //     {
    //         title: 'Специальные предложения',
    //         products: products.slice(3, 6),
    //     },
    //     {
    //         title: 'Лучшие продажи',
    //         products: products.slice(6, 9),
    //     },
    // ];


    
    

    const categoryes = useSelector(state => state.category)
    const popularProducts = useSelector(state => state.shop.popularProducts)
    const dispatch = useDispatch();
    
    useEffect(() => {
        getCategories()
        
    },[])

    async function getCategories() {
        await dispatch(categoryGetItem())
    }
    
    useEffect(() => {
        // console.log(categoryes)
        if (typeof(categoryes[0]) !== 'undefined'){
            dispatch(getPopularProducts(categoryes[1].url))
        }
    },[categoryes])
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{`TSTTOOLS`}</title>
            </Helmet>

            <BlockSlideShow categoryesList={categoryes} />

            <BlockFeatures />
            <BlockTabbedProductsCarousel 
                products={popularProducts}
                title="Популярные товары" 
                layout="grid-4" 
            />

            <BlockBanner />

            <BlockProducts
                title="Бестселлеры"
                layout="large-first"
                featuredProduct={popularProducts[0]}
                products={popularProducts.slice(1, 7)}
            />

            {/* <BlockCategories title="Популярные категории" layout="classic" categories={categories} /> */}

            {/* <BlockTabbedProductsCarousel title="Новые товары" layout="horizontal" rows={2} /> */}

            {/* <BlockPosts title="Последние новости" layout="list-sm" posts={posts} /> */}

            {/* <BlockProductColumns columns={columns} /> */}
        </React.Fragment>
    );
}

export default HomePageOne;
