// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import SlickWithPreventSwipeClick from '../shared/SlickWithPreventSwipeClick';
import {
    ManuFactureSvg,
    LightBulbSvg,
    CalendarSvg,
} from '../../svg';

// data stubs
import theme from '../../data/theme';


const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs(props) {
    const { layout } = props;
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`About Us — ${theme.name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/aboutus.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <div className="about-us__title">
                                <div>
                                    <Link to="/"> <img height="60" src="images/logos/tsttools-logo.png" alt="TSTTOOLS Logo" /></Link>
                                </div>
                            </div>

                            <div className="about-us__text typography">
                                <p>
                                    Наша компания надежный и опытный поставщик металлообрабатывающего инструмента, технологической оснастки и комплектующих для станков с ЧПУ и не только

                                </p>
                                <p>
                                    Все наши работники являются высококвалифицированными специалистами, качественно оказывающими свои услуги и выполняющими пожелания заказчика.
                                </p>
                            </div>

                            {/* <div className="about-us__team">
                                <h2 className="about-us__team-title">Наша команда</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    Хотите работать в нашей дружной команде?
                                    <br />
                                    <Link to="/site/contact-us">Оставьте контакты</Link>
                                    {' '}
                                    и мы рассмотрим вашу кандидатуру.
                                </div>

                                <div className="about-us__teammates teammates">
                                    <SlickWithPreventSwipeClick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Michael Russo</div>
                                            <div className="teammate__position text-muted">Chief Executive Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Katherine Miller</div>
                                            <div className="teammate__position text-muted">Marketing Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Anthony Harris</div>
                                            <div className="teammate__position text-muted">Finance Director</div>
                                        </div>
                                    </SlickWithPreventSwipeClick>
                                </div>
                            </div> */}
                        </div>
                        <div className={`block block-features block-features--layout--classic`}>
                            <div className="container">
                                <div className="block-features__list">
                                    <div className="block-features__item">
                                        <div className="block-features__icon">
                                            <ManuFactureSvg />
                                        </div>
                                        <div className="block-features__content">
                                            <div className="block-features__title">Сотрудничество</div>
                                            <div className="block-features__subtitle">Постоянно пополняемый склад инструмента</div>
                                        </div>
                                    </div>
                                    <div className="block-features__divider" />
                                    <div className="block-features__item">
                                        <div className="block-features__icon">
                                            <LightBulbSvg />
                                        </div>
                                        <div className="block-features__content">
                                            <div className="block-features__title">Контроль</div>
                                            <div className="block-features__subtitle">Подробная консультация и подбор</div>
                                        </div>
                                    </div>
                                    <div className="block-features__divider" />
                                    <div className="block-features__item">
                                        <div className="block-features__icon">
                                            <CalendarSvg />
                                        </div>
                                        <div className="block-features__content">
                                            <div className="block-features__title">Сроки</div>
                                            <div className="block-features__subtitle">Ответ в день обращения</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
