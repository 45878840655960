// react
import React from 'react';


export default function BlockMap() {
    return (
        <div className="block-map block">
            <div className="block-map__body">
                {/* <iframe
                    title="Google Map"
                    src="https://maps.google.com/maps?q=Holbrook-Palmer%20Park&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                /> */}
                <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A66592b6b0394814ade7a95581c4f70fabc9c863d7afdc2b8605b2b383cee0110&amp;source=constructor" 
                width="100%" 
                height="100%" 
                frameBorder="0"
                ></iframe>
            </div>
        </div>
    );
}
