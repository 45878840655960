import { combineReducers } from 'redux';

// reducers
import cartReducer from './cart';
import compareReducer from './compare';
import currencyReducer from './currency';
import localeReducer from './locale';
import mobileMenuReducer from './mobile-menu';
import quickviewReducer from './quickview';
import sidebarReducer from './sidebar';
import wishlistReducer from './wishlist';
import categoryReducer from './category';
import shopReducer from './shop/shopReducer';
import productReducer from './product';
import orderReducer from './order';

export default combineReducers({
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    category: categoryReducer,
    shop: shopReducer,
    productCard: productReducer,
    orders: orderReducer
});
