// react
import React from 'react';


function ProductTabDescription(props) {
    
    return (
        <div className="typography">
            <h3>Описание товара</h3>
            <p>
                {props.desc}
            </p>
        </div>
    );
}

export default ProductTabDescription;
