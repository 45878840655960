import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN, GET_MOBILE_LINKS } from './mobileMenuActionTypes';
import mobileMenuLinks from '../../data/mobileMenu';

const initialState = {
    open: false,
    links:[]
};

function setMobileLinks(state, links){
    let categoryMainLink = mobileMenuLinks.find(x => x.label === "Категории")
    links.forEach(element => {
        let child_list = element.child_list;
        let category = categoryMainLink.children.find(x => x.label === element.name);
        if (typeof category !== 'undefined'){
            category.url = "/shop/category/" + element.category_uuid
            category.children.forEach(element =>{

                let child_category = child_list.find(x => x.name === element.label)
                if (typeof(child_category) != 'undefined'){
                    element.url = "/shop/category/" + child_category.category_uuid;
                }

            })
        }
    });



    // return mobileMenuLinks;
    return {
        ...state,
        links: mobileMenuLinks,
    };
}

export default function mobileMenuReducer(state = initialState, action) {
    switch (action.type) {
    case MOBILE_MENU_OPEN:
        return {
            ...state,
            open: true,
        };
    case MOBILE_MENU_CLOSE:
        return {
            ...state,
            open: false,
        };
    case GET_MOBILE_LINKS:
        return setMobileLinks(state, action.payload);
    default:
        return state;
    }
}
