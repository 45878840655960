import { ADD_ORDER } from "./orderActionsTypes";


export default function orderReducer(state = {}, action){
    switch (action.type){
        case ADD_ORDER:
            return state;
        default: 
            return state;
    }
}