export default [
	{
		id: 1,
		name: "Защитное стекло D27,9x4,1 мм",
		category: "zashchitnye-stekla-dlya-lazerov",
		description: "Наружный диаметр: 27,9мм, Совместимость: Raytools, Покрытие AR\\/AR (1064 нм), Толщина 4,1 мм",
		sku: "gl27941",
		badges: "badge",
		price: 750,
		image: "images\\/catalog\\/laserniy-stanki\\/zashchitnye-stekla-dlya-lazerov\\/allglass.jpg"
	},
	{
		id: 2,
		name: "Защитное стекло D24,9x1,5 мм",
		category: "zashchitnye-stekla-dlya-lazerov",
		description: "Наружный диаметр: 24,9мм, Совместимость: Raytools, Bodor; Покрытие AR\\/AR (1064 нм), Толщина 1,5 мм",
		sku: "gl24915",
		badges: "badge",
		price: 650,
		image: "images\\/catalog\\/laserniy-stanki\\/zashchitnye-stekla-dlya-lazerov\\/allglass.jpg"
	},
	{
		id: 3,
		name: "Защитное стекло D30x5 мм",
		category: "zashchitnye-stekla-dlya-lazerov",
		description: "Наружный диаметр: 30 мм, Совместимость: WSX, Bodor; Покрытие AR\\/AR (1064 нм), Толщина 5 мм",
		sku: "gl305",
		badges: "badge",
		price: 525,
		image: "images\\/catalog\\/laserniy-stanki\\/zashchitnye-stekla-dlya-lazerov\\/allglass.jpg"
	},
	{
		id: 4,
		name: "Защитное стекло D22,35x4 мм",
		category: "zashchitnye-stekla-dlya-lazerov",
		description: "Наружный диаметр: 22,35 мм, Совместимость: WSX; Покрытие AR\\/AR (1064 нм), Толщина 4 мм",
		sku: "gl22354",
		badges: "badge",
		price: 725,
		image: "images\\/catalog\\/laserniy-stanki\\/zashchitnye-stekla-dlya-lazerov\\/allglass.jpg"
	},
	{
		id: 5,
		name: "Защитное стекло D37x7 мм",
		category: "zashchitnye-stekla-dlya-lazerov",
		description: "Наружный диаметр: 37 мм, Совместимость: Bodor; Покрытие AR\\/AR (1064 нм), Толщина 7 мм",
		sku: "gl377",
		badges: "badge",
		price: 900,
		image: "images\\/catalog\\/laserniy-stanki\\/zashchitnye-stekla-dlya-lazerov\\/allglass.jpg"
	},
	{
		id: 6,
		name: "Керамическая проставка D 32 ",
		category: "keramicheskie-prostavki",
		description: "Диаметр 32\\/28,5 мм\nВысота 12 мм\nРезьба М14",
		sku: "crmcr_32",
		badges: "badge",
		price: 1350,
		image: "images\\/catalog\\/laserniy-stanki\\/keramicheskie-prostavki\\/ceramicring.jpg"
	},
	{
		id: 7,
		name: "Керамическая проставка D 28 ",
		category: "keramicheskie-prostavki",
		description: "Диаметр 28\\/24,5 мм\nВысота 12 мм\nРезьба М11",
		sku: "crmcr_28",
		badges: "badge",
		price: 1650,
		image: "images\\/catalog\\/laserniy-stanki\\/keramicheskie-prostavki\\/ceramicring.jpg"
	},
	{
		id: 8,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.08",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 9,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.10",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 10,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.12",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 11,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.15",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 12,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.18",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 13,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.20",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 14,
		name: "Сопло одинарное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng32.25",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 15,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.08",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 16,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.10",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 17,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.12",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 18,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.15",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 19,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.18",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 20,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.20",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 21,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.25",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 22,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.30",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 23,
		name: "Сопло двойное D32 ",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.35",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 24,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.08ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 25,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.10ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 26,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.12ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 27,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.15ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 28,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.18ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 29,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.20ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 30,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.25ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 31,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.30ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 32,
		name: "Сопло двойное D32  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 32\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl32.35ch",
		badges: "badge",
		price: 890,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 33,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.08",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 34,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.10",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 35,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.12",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 36,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.15",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 37,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.18",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 38,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.20",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 39,
		name: "Сопло одинарное D28",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_sng28.25",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_single.jpg"
	},
	{
		id: 40,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.08",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 41,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.10",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 42,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.12",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 43,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.15",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 44,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.18",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 45,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.20",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 46,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.25",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 47,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.30",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 48,
		name: "Сопло двойное D28 ",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.35",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_double.jpg"
	},
	{
		id: 49,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.08",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 50,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.10",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 51,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.12",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 52,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.15",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 53,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.18",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 54,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.20",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 55,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.25",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 56,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.30",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 57,
		name: "Сопло двойное D28  (Chrome)",
		category: "sopla",
		description: "Диаметр, D (мм): 28\nВысота, H (мм): 15\nРезьба: M14",
		sku: "nzl_dbl28.35",
		badges: "badge",
		price: 590,
		image: "images\\/catalog\\/laserniy-stanki\\/sopla\\/nozzle_chrome.jpg"
	},
	{
		id: 58,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                      Ширина ручья,мм 20          Длина,мм 500 \nРабочая высота, мм 150\nРадиус, мм 2.0\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал ",
		sku: "m3148.86.20.150.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3148.jpg"
	},
	{
		id: 59,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 20   Длина 550 мм\nРабочая высота, мм 150\nРадиус, мм 2.0\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал С45",
		sku: "m3148.86.20.150.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3148.jpg"
	},
	{
		id: 60,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                        Ширина ручья,мм 24          Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 2.5\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3149.86.25.150.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3149.jpg"
	},
	{
		id: 61,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                        Ширина ручья,мм 24                Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 2.5\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3149.86.25.150.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3149.jpg"
	},
	{
		id: 62,
		name: "Матрица 3136  30°  H_100  R_0,6 ",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                        Ширина ручья,мм 6           Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3136.30.06.100.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 63,
		name: "Матрица 3136 30°  H_100  R_1  (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                        Ширина ручья,мм 6                Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 0.6\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3136.30.06.100.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 64,
		name: "Матрица 3137  30°  H_100 R_1 ",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                      Ширина ручья,мм 8                           Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 1.0\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3137.30.10.100.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 65,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 8            Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3137.30.10.100.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 66,
		name: "Матрица 3138  30°  H_100 R_1",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                        Ширина ручья,мм 10                      Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3138.30.10.100.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 67,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 10               Длина,мм 500 \nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3138.30.10.100.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 68,
		name: "Матрица 3139 30°  H_100 R_1 ",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                          Ширина ручья,мм 12                            Длина, мм 500\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3139.30.10.100.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 69,
		name: "Матрица 3139 30° H_100 R_1  (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 12         Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3139.30.10.100.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 70,
		name: "Матрица 3140 30° H_100 R_1,6",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 16         Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 1,6\nМаксимальная допустимая нагрузка, Т\\/м 45\nМатериал C45",
		sku: "m3140.30.16.100.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 71,
		name: "Матрица 3140 30° H_100 R_1,6  (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 16         Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 1,6\nМаксимальная допустимая нагрузка, Т\\/м 45\nМатериал C45",
		sku: "m3140.30.16.100.550",
		badges: "badge",
		price: 27800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 72,
		name: "Матрица 3141 30° H_100 R_2",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 20         Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 2\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3141.30.20.100.500",
		badges: "badge",
		price: 25670,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 73,
		name: "Матрица 3141 30° H_100 R_2 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 20         Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 2\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3141.30.20.100.550",
		badges: "badge",
		price: 28640,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3136_3137_3138_3139_3140_3141.jpg"
	},
	{
		id: 74,
		name: "Матрица ° H_150 mm, V-6,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 6         Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3143.86.06.150.500",
		badges: "badge",
		price: 34510,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3143.jpg"
	},
	{
		id: 75,
		name: "Матрица 3143 86° H_150 R_0,6 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 6         Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3143.86.06.150.550",
		badges: "badge",
		price: 82300,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3143.jpg"
	},
	{
		id: 76,
		name: "Матрица ° H_150 mm, V-8,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 8         Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3144.86.08.150.550",
		badges: "badge",
		price: 32100,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3144.jpg"
	},
	{
		id: 77,
		name: "Матрица 3144 86° H_150 R_0,8 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 8         Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3144.86.08.150.550",
		badges: "badge",
		price: 84255,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3144.jpg"
	},
	{
		id: 78,
		name: "Матрица ° H_150 mm, V-10,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 10         Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3145.86.10.150.500",
		badges: "badge",
		price: 35090,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3145.jpg"
	},
	{
		id: 79,
		name: "Матрица ° H_150mm,V-10,R_0,5 Max T\\m-100 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 10         Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3145.86.10.150.550",
		badges: "badge",
		price: 84600,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3145.jpg"
	},
	{
		id: 80,
		name: "Матрица ° H_150 mm, V-12,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 12         Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3146.86.10.150.500",
		badges: "badge",
		price: 37680,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3146.jpg"
	},
	{
		id: 81,
		name: "Матрица 3146 86° H_150 R_1 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 12         Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3146.86.10.150.550",
		badges: "badge",
		price: 90200,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3146.jpg"
	},
	{
		id: 82,
		name: "Матрица 3150 30° H_150 R_0,6",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 6           Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3150.30.06.150.500",
		badges: "badge",
		price: 48500,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3150.jpg"
	},
	{
		id: 83,
		name: "Матрица ° H_150 mm, V-6,R_1 Max T\\m-50 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 6           Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3150.30.06.150.550",
		badges: "badge",
		price: 89130,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3150.jpg"
	},
	{
		id: 84,
		name: "Матрица 3151 30° H_150 R_1",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 8           Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3151.30.10.150.500",
		badges: "badge",
		price: 48900,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3151.jpg"
	},
	{
		id: 85,
		name: "Матрица 3151 30° H_150 R_1 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 8           Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3151.30.10.150.550",
		badges: "badge",
		price: 90450,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3151.jpg"
	},
	{
		id: 86,
		name: "Матрица 3152 30° H_150 R_1",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 10           Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3152.30.10.150.500",
		badges: "badge",
		price: 48600,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3152.jpg"
	},
	{
		id: 87,
		name: "Матрица 3152 30° H_150 R_1 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 10           Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3152.30.10.150.550",
		badges: "badge",
		price: 88760,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3152.jpg"
	},
	{
		id: 88,
		name: "Матрица ° H_150 mm, V-12,R_1 Max T\\m-40",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 12           Длина,мм 500\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3153.30.10.150.500",
		badges: "badge",
		price: 50070,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3153.jpg"
	},
	{
		id: 89,
		name: "Матрица ° H_150 mm, V-12,R_1 Max T\\m-40 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 30                                       Ширина ручья,мм 12           Длина,мм 550\nРабочая высота, мм 150\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3153.30.10.150.550",
		badges: "badge",
		price: 105370,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3153.jpg"
	},
	{
		id: 90,
		name: "Пуансон 1238 A 28 R_1 H_195",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 28                                    Длина,мм 500\nРабочая высота, мм 195\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 80\nМатериал 42CrMo4",
		sku: "p1238.28.10.195.500",
		badges: "badge",
		price: 75100,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1238.jpg"
	},
	{
		id: 91,
		name: "Пуансон 1238 A 28 R_1 H_195(сегментированный)",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 28                                    Длина,мм 550\nРабочая высота, мм 195\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 80\nМатериал 42CrMo4",
		sku: "p1238.28.10.195.550",
		badges: "badge",
		price: 110390,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1238.jpg"
	},
	{
		id: 92,
		name: "Пуансон 1302 A 28 R_1 H_220",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 28                                    Длина,мм 500\nРабочая высота, мм 220\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 80\nМатериал 42CrMo4",
		sku: "p1302.28.10.220.500",
		badges: "badge",
		price: 124690,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1302.jpg"
	},
	{
		id: 93,
		name: "Пуансон 1302 A 28 R_1 H_220 (сегментированный)",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 28                                    Длина,мм 550\nРабочая высота, мм 220\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 80\nМатериал 42CrMo4",
		sku: "p1302.28.10.220.550",
		badges: "badge",
		price: 127450,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1302.jpg"
	},
	{
		id: 94,
		name: "Пуансон 1236 A 60 R_0,8 H_195",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 60                                    Длина,мм 500\nРабочая высота, мм 195\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал 42CrMo4",
		sku: "p1236.60.08.195.500",
		badges: "badge",
		price: 125120,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1236.jpg"
	},
	{
		id: 95,
		name: "Пуансон 1236 A 60 R_0,8 H_195 (сегментированный) ",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 60                                    Длина,мм 550\nРабочая высота, мм 195\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал 42CrMo4",
		sku: "p1236.60.08.195.550",
		badges: "badge",
		price: 127800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1236.jpg"
	},
	{
		id: 96,
		name: "Пуансон 1234 A 86 R_0,6 H_195",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                    Длина,мм 500\nРабочая высота, мм 195\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 30\nМатериал 42CrMo4",
		sku: "p1234.86.06.195.500",
		badges: "badge",
		price: 76460,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1234.jpg"
	},
	{
		id: 97,
		name: "Пуансон 1234 A 86 R_0,6 H_195 (сегментированный) ",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                    Длина,мм 550\nРабочая высота, мм 195\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 30\nМатериал 42CrMo4",
		sku: "p1234.86.06.195.550",
		badges: "badge",
		price: 89050,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1234.jpg"
	},
	{
		id: 98,
		name: "Пуансон 1233 A 86 R_0,8 H_195",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                    Длина,мм 500\nРабочая высота, мм 195\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал 42CrMo4",
		sku: "p1233.86.08.195.500",
		badges: "badge",
		price: 105320,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1233.jpg"
	},
	{
		id: 99,
		name: "Пуансон 1233 A 86 R_0,8 H_195 (сегментированный) ",
		category: "trumpf-punch",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                    Длина,мм 550\nРабочая высота, мм 195\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал 42CrMo4",
		sku: "p1233.86.08.195.550",
		badges: "badge",
		price: 135690,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-punch\\/Punch1233.jpg"
	},
	{
		id: 100,
		name: "Матрица ° H_100 mm, V-8,R_0,5 Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 8           Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3124.86.08.100.500",
		badges: "badge",
		price: 30210,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3124_3125_3126.jpg"
	},
	{
		id: 101,
		name: "Матрица 3124 86° H_100 V-8 R_0,8 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 8           Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3124.86.08.100.550",
		badges: "badge",
		price: 71050,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3124_3125_3126.jpg"
	},
	{
		id: 102,
		name: "Матрица ° H_100 mm, V-10, R_0,5, Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 10           Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3125.86.10.100.500",
		badges: "badge",
		price: 30180,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3124_3125_3126.jpg"
	},
	{
		id: 103,
		name: "Матрица 3125 86° H_100 R_1 (сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 10           Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3125.86.10.100.550",
		badges: "badge",
		price: 71230,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3124_3125_3126.jpg"
	},
	{
		id: 104,
		name: "Матрица ° H_100 mm, V-12,R_0,5, Max T\\m-100",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 12           Длина,мм 500\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3126.86.10.100.500",
		badges: "badge",
		price: 32800,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3124_3125_3126.jpg"
	},
	{
		id: 105,
		name: "Матрица 3126 86° H_100 R_1(сегментированная)",
		category: "trumpf-matrix",
		description: "Система крепления инструмента trumpf - Wila\nУгол 86                                       Ширина ручья,мм 12           Длина,мм 550\nРабочая высота, мм 100\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m3126.86.10.100.550",
		badges: "badge",
		price: 75100,
		image: "images\\/catalog\\/gibochniy-stanki\\/trumpf\\/trumpf-matrix\\/matrix3124_3125_3126.jpg"
	},
	{
		id: 106,
		name: "Пуансон 1086 A 30 R_0,6 H_140",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 30                                    Длина,мм 550\nРабочая высота, мм 140\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал С45",
		sku: "p1086.30.06.140.550",
		badges: "badge",
		price: 125000,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1086.jpg"
	},
	{
		id: 107,
		name: "Пуансон 1052 30° H135 R0,5",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 30°                                        Длина 835 мм\nРабочая высота, мм 135\nРадиус, мм 0.5\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал 42CrMo",
		sku: "p1052.30.05.135.835",
		badges: "badge",
		price: 68000,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1052.jpg"
	},
	{
		id: 108,
		name: "Пуансон 1284 A 60 R_0,8 H_135",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                    Длина,мм 835\nРабочая высота, мм 135\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 70\nМатериал С45",
		sku: "p1284.60.08.135.835",
		badges: "badge",
		price: 69500,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1284.jpg"
	},
	{
		id: 109,
		name: "Пуансон 1190 A 60 R_0,8 H_160",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                    Длина,мм 835\nРабочая высота, мм 160\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал С45",
		sku: "p1190.60.08.160.835",
		badges: "badge",
		price: 139780,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1190.jpg"
	},
	{
		id: 110,
		name: "Пуансон 1163 A 60 R_0,8 H_130",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                    Длина,мм 835\nРабочая высота, мм 130\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал С45",
		sku: "p1163.60.08.130.835",
		badges: "badge",
		price: 141350,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1163.jpg"
	},
	{
		id: 111,
		name: "Пуансон 1281 A 85 R_0,8 H_104,65",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 85                                    Длина,мм 835\nРабочая высота, мм 104,65\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал С45",
		sku: "p1281.85.08.104,65.835",
		badges: "badge",
		price: 48550,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1281.jpg"
	},
	{
		id: 112,
		name: "Пуансон A 30,R_0,5, H_200 mm,max T\\M 50",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 30                                    Длина,мм 835\nРабочая высота, мм 200\nРадиус, мм 0,5\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал С45",
		sku: "p1292.30.05.200.835",
		badges: "badge",
		price: 99100,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1292.jpg"
	},
	{
		id: 113,
		name: "Пуансон 1192 A 26 R_3 H_117",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 26                                    Длина,мм 835\nРабочая высота, мм 117\nРадиус, мм 3\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал С45",
		sku: "p1192.26.30.117.835",
		badges: "badge",
		price: 46320,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1192.jpg"
	},
	{
		id: 114,
		name: "Одиночная Т-образная матрица H80мм V-8, A- 60, R_0,5, S-14, Rf\\P- R0,7, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 8           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 0,5\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал C45",
		sku: "m3020.60.05.80.835",
		badges: "badge",
		price: 50670,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3021.jpg"
	},
	{
		id: 115,
		name: "Одиночная Т-образная матрица H80мм V-10, A- 60, R_0,6, S-18, Rf\\P- R0,5, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 10           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал C45",
		sku: "m3021.60.06.80.835",
		badges: "badge",
		price: 50670,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3021.jpg"
	},
	{
		id: 116,
		name: "Одиночная Т-образная матрица H80мм V-12, A- 60, R_0,8, S-18, Rf\\P- R0,5, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 12           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал C45",
		sku: "m3022.60.08.80.835",
		badges: "badge",
		price: 50670,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3022.jpg"
	},
	{
		id: 117,
		name: "Одиночная Т-образная матрица H80мм V-16, A- 60, R_3, S-24, Rf\\P- R0,7, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 16           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 3\nМаксимальная допустимая нагрузка, Т\\/м 75\nМатериал C45",
		sku: "m3023.60.30.80.835",
		badges: "badge",
		price: 50670,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3023.jpg"
	},
	{
		id: 118,
		name: "Одиночная Т-образная матрица H80мм V-6, A- 30, R_1, S-14, Rf\\P- R -1, Max T\\M- 35",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                                      Ширина ручья,мм 6           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 35\nМатериал C45",
		sku: "m3042.30.06.80.835",
		badges: "badge",
		price: 57020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3042.jpg"
	},
	{
		id: 119,
		name: "Одиночная Т-образная матрица H80мм V-8, A- 30, R_0,8, S-18, Rf\\P- R2, Max T\\M- 35",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                                      Ширина ручья,мм 8           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3043.30.08.80.835",
		badges: "badge",
		price: 57020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3043.jpg"
	},
	{
		id: 120,
		name: "Одиночная Т-образная матрица H80мм V-10, A- 30, R_1, S-24, Rf\\P- R2, Max T\\M- 50",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                                      Ширина ручья,мм 10           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал C45",
		sku: "m3044.30.10.80.835",
		badges: "badge",
		price: 57020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3044.jpg"
	},
	{
		id: 121,
		name: "Одиночная Т-образная матрица H80мм V-12, A- 30, R_1,5, S-24, Rf\\P- R2, Max T\\M- 40",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                                      Ширина ручья,мм 10           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3045.30.10.80.835",
		badges: "badge",
		price: 57020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3045.jpg"
	},
	{
		id: 122,
		name: "Одиночная Т-образная матрица H80мм V-20, A- 30, R_2,5, S-35, Rf\\P- R5, Max T\\M- 50",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                                      Ширина ручья,мм 20           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 2,5\nМаксимальная допустимая нагрузка, Т\\/м 55\nМатериал C45",
		sku: "m3047.30.25.80.835",
		badges: "badge",
		price: 57020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3047.jpg"
	},
	{
		id: 123,
		name: "Одиночная Т-образная матрица H120мм V-8, A- 60, R_0,5, S-14, Rf\\P- R0,7, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 8           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 0,5\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал C45",
		sku: "m3060.60.05.80.835",
		badges: "badge",
		price: 79800,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3060.jpg"
	},
	{
		id: 124,
		name: "Одиночная Т-образная матрица H120мм V-10, A- 60, R_0,6, S-18, Rf\\P- R0,5, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 10           Длина,мм 835\nРабочая высота, мм 120\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал C45",
		sku: "m3061.60.06.120.835",
		badges: "badge",
		price: 79800,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3061.jpg"
	},
	{
		id: 125,
		name: "Одиночная Т-образная матрица H120мм V-12, A- 60, R_0,8, S-18, Rf\\P- R0,5, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 12           Длина,мм 835\nРабочая высота, мм 120\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал C45",
		sku: "m3062.60.08.835",
		badges: "badge",
		price: 79800,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3062.jpg"
	},
	{
		id: 126,
		name: "Многоручьевая Х-образная матрица V-35, V-10, V-16, V-22 60 градусов",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 10; 16; 22;35           Длина,мм 835\nРабочая высота, мм 60\nРадиус, мм 1,5; 2; 2,5; 3\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал C45",
		sku: "mx2034.60",
		badges: "badge",
		price: 35020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix2034.jpg"
	},
	{
		id: 127,
		name: " Матрица 2087 H_60 A- 30 R_3",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                                      Ширина ручья,мм 25           Длина,мм 835\nРабочая высота, мм 60\nРадиус, мм 3\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m2087.30.30.60.835",
		badges: "badge",
		price: 50500,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix2087.jpg"
	},
	{
		id: 128,
		name: " Матрица 2088 H_80 A- 45 R_4",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 45                                      Ширина ручья,мм 32           Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 4\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m2088.45.40.80.835",
		badges: "badge",
		price: 51390,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix2088.jpg"
	},
	{
		id: 129,
		name: "Пуансон A-88, R_ 0,6, H_ 84,15 mm, max T\\M-20",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 84,15\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 20\nМатериал С45",
		sku: "p1020.88.06.84,15.835",
		badges: "badge",
		price: 85670,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1020.jpg"
	},
	{
		id: 130,
		name: "Пуансон 1175 A-88 R_ 0,8 H_ 89,65",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 89.65\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал С45",
		sku: "p1175.88.08.89,65.835",
		badges: "badge",
		price: 91400,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1175.jpg"
	},
	{
		id: 131,
		name: "Пуансон 1061 A-88 R_ 0,8 H_ 120",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 120\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал С45",
		sku: "p1061.88.08.120.835",
		badges: "badge",
		price: 128910,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1061.jpg"
	},
	{
		id: 132,
		name: "Пуансон 1082 A-88 R_ 0,8 H_ 104,65",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 104,65\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 45\nМатериал С45",
		sku: "p1082.88.08.104,65.835",
		badges: "badge",
		price: 135500,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1082.jpg"
	},
	{
		id: 133,
		name: "Пуансон 1031 A-88 R_ 0,8 H_ 165",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 165\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал С45",
		sku: "p1031.88.08.165.835",
		badges: "badge",
		price: 199400,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1031.jpg"
	},
	{
		id: 134,
		name: "Пуансон 1290 A-88 R_ 0,6 H_ 89,7",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 89,70\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 30\nМатериал С45",
		sku: "p1290.88.06.89,70.835",
		badges: "badge",
		price: 111200,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1290.jpg"
	},
	{
		id: 135,
		name: "Пуансон 1291 A-88 R_ 0,8 H_ 200",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 88                                    Длина,мм 835\nРабочая высота, мм 200\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 85\nМатериал С45",
		sku: "p1291.88.08.200.835",
		badges: "badge",
		price: 219100,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1291.jpg"
	},
	{
		id: 136,
		name: "Пуансон 1191 A-60 R_ 0,8 H_ 115",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                    Длина,мм 835\nРабочая высота, мм 115\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 60\nМатериал С45",
		sku: "p1191.60.08.115.835",
		badges: "badge",
		price: 81770,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1191.jpg"
	},
	{
		id: 137,
		name: "Пуансон 1272 A-60 R_ 0,8 H_ 115",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                    Длина,мм 835\nРабочая высота, мм 115\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 40\nМатериал С45",
		sku: "p1272.60.08.115.835",
		badges: "badge",
		price: 113090,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1272.jpg"
	},
	{
		id: 138,
		name: "Пуансон  1271 A-60  R_ 0,8 H_ 115",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                    Длина,мм 835\nРабочая высота, мм 115\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал С45",
		sku: "p1271.60.08.115.835",
		badges: "badge",
		price: 97660,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1271.jpg"
	},
	{
		id: 139,
		name: "Пуансон 1284 A-60 R_ 0,8 H_ 135",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 60                                  Длина,мм 835\nРабочая высота, мм 135\nРадиус, мм 0,8\nМаксимальная допустимая нагрузка, Т\\/м 70\nМатериал С45",
		sku: "p1284.60.08.135.835",
		badges: "badge",
		price: 68101,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1284.jpg"
	},
	{
		id: 140,
		name: " Матрица 2082 H_75  A- 60 R_5",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 50          Длина,мм 835\nРабочая высота, мм 75\nРадиус, мм 5\nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал C45",
		sku: "m2082.60.50.75.835",
		badges: "badge",
		price: 89020,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix2082.jpg"
	},
	{
		id: 141,
		name: "Одиночная Т-образная матрица H120мм V-16, A- 60, R_3, S-24, Rf\\P- R0,7, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 16           Длина,мм 835\nРабочая высота, мм 120\nРадиус, мм 3\nМаксимальная допустимая нагрузка, Т\\/м 75\nМатериал C45",
		sku: "m3063.60.30.120.835",
		badges: "badge",
		price: 80900,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3063.jpg"
	},
	{
		id: 142,
		name: "Одиночная Т-образная матрица H120мм V-20, A- 60, R_3, S-30, Rf\\P- R0,7, Max T\\M- 60",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 60                                      Ширина ручья,мм 20           Длина,мм 835\nРабочая высота, мм 120\nРадиус, мм 3\nМаксимальная допустимая нагрузка, Т\\/м 70\nМатериал C45",
		sku: "m3064,60,30.120.835",
		badges: "badge",
		price: 80900,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3064.jpg"
	},
	{
		id: 143,
		name: "Подпружиненная матрица для завальцовки H135 mm, V-12, R_3, T\\M 100, рабочий градус- 26",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 26                              Рабочий градус 26              Ширина ручья,мм 12         Длина,мм 835\nРабочая высота, мм 135\nРадиус, мм 3                     \nМаксимальная допустимая нагрузка, Т\\/м 100\nМатериал 42CrMo",
		sku: "m3040.26.30.135.835",
		badges: "badge",
		price: 158900,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3040.jpg"
	},
	{
		id: 144,
		name: "Подпружиненная матрица для завальцовки H110 mm, V-10, R_1,5, T\\M 80, рабочий градус- 30",
		category: "amada-matrix",
		description: "Система крепления инструмента AMADA\nУгол 30                              Рабочий градус 30              Ширина ручья,мм 8           Длина,мм 835\nРабочая высота, мм 110\nРадиус, мм 1,5  \nМаксимальная допустимая нагрузка, Т\\/м 80\nМатериал 42CrMo",
		sku: "m3041.30.15.110.835",
		badges: "badge",
		price: 121600,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-matrix\\/matrix3041.jpg"
	},
	{
		id: 145,
		name: "Инструмент для завальцовки: пуансон H 128 mm,R_0,6, T\\M 80, ",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол 24                                 Длина,мм 835\nРабочая высота, мм 128\nРадиус, мм 0,6\nМаксимальная допустимая нагрузка, Т\\/м 80\nМатериал С45",
		sku: "p1197.24.06.128.835",
		badges: "badge",
		price: 110500,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch1197.jpg"
	},
	{
		id: 146,
		name: "Инструмент для завальцовки: матрица H 80 mm, R_1 T\\M50",
		category: "amada-punch",
		description: "Система крепления инструмента AMADA\nУгол -                                      Ширина ручья,мм 12         Длина,мм 835\nРабочая высота, мм 80\nРадиус, мм 1\nМаксимальная допустимая нагрузка, Т\\/м 50\nМатериал 42CrMo",
		sku: "p3178.0.10.80.835",
		badges: "badge",
		price: 82390,
		image: "images\\/catalog\\/gibochniy-stanki\\/amada\\/amada-punch\\/Punch3178.jpg"
	}
]

