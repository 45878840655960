import axios from 'axios';
import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN, GET_MOBILE_LINKS } from './mobileMenuActionTypes';


export function mobileMenuOpen() {
    return { type: MOBILE_MENU_OPEN };
}

export function mobileMenuClose() {
    return { type: MOBILE_MENU_CLOSE };
}

export function mobileMenuUpdateLinks(links) {
    return { 
        type: GET_MOBILE_LINKS,
        payload: links
        
    };
}


export const mobileMenuGetLinks = () => {
    return dispatch => {
        return axios.get('https://gateway.okibi-team.ru/category',{
            responseType: 'json',
        }).then((res) => {
            dispatch(mobileMenuUpdateLinks(res.data.category_list));
        })
    }
}
