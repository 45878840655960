// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// data stubs
import theme from '../../data/theme';


function SitePageNotFound() {
    return (
        <div className="block">
            <Helmet>
                <title>{`404 Page Not Found — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">
                        Ошибка 404
                    </div>

                    <div className="not-found__content">
                        <h1 className="not-found__title">Страница не найдена</h1>

                        <p className="not-found__text">
                            Попробуйте воспользоваться поиском
                        </p>

                        <form className="not-found__search">
                            <input type="text" className="not-found__search-input form-control" placeholder="Введите запрос" />
                            <button type="submit" className="not-found__search-button btn btn-primary">Поиск</button>
                        </form>

                        <p className="not-found__text">
                            Или вернитесь на главную страницу
                        </p>

                        <Link to="/" className="btn btn-secondary btn-sm">Вернуться на главную</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
