export default [
    {
        type: 'link',
        label: 'Главная',
        url: '/',
    },

    {
        type: 'button',
        label: 'Категории',
        url: '',
        children: [
            {
                type: 'link',
                label: 'Расходные материалы',
                url: '/dev',
                children: [
                    { type: 'link', label: 'Лазерные станки', url: '/dev' },
                    { type: 'link', label: 'Электроэрозия', url: '/dev' },
                    { type: 'link', label: 'Сварка', url: '/dev' },
                    { type: 'link', label: 'Компрессорное оборудование', url: '/dev' },
                ],
            },
            {
                type: 'link',
                label: 'Гибочный инструмент',
                url: '/dev',
                children: [
                    { type: 'link', label: 'Amada Promecam system', url: '/dev' },
                    { type: 'link', label: 'Wila Trumpf system', url: '/dev' },
                ],
            },
        ],
    },
    {
        type: 'link',
        label: 'Аккаунт',
        url: '/account',
        children: [
            { type: 'link', label: 'Login', url: '/account/login' },
            { type: 'link', label: 'Dashboard', url: '/account/dashboard' },
            { type: 'link', label: 'Edit Profile', url: '/account/profile' },
            { type: 'link', label: 'Order History', url: '/account/orders' },
            { type: 'link', label: 'Address Book', url: '/account/addresses' },
            { type: 'link', label: 'Change Password', url: '/account/password' },
        ],
    },
    {
        type: 'link',
        label: 'Новости',
        url: '/blog/category-classic',
        children: [
            { type: 'link', label: 'Blog Classic', url: '/blog/category-classic' },
            { type: 'link', label: 'Blog Grid', url: '/blog/category-grid' },
            { type: 'link', label: 'Blog List', url: '/blog/category-list' },
            { type: 'link', label: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
            { type: 'link', label: 'Post Page', url: '/blog/post-classic' },
            { type: 'link', label: 'Post Without Sidebar', url: '/blog/post-full' },
        ],
    },

    {
        type: 'link',
        label: 'О нас',
        url: '/site/about-us',
        children: [
            { type: 'link', label: 'About Us', url: '/site/about-us' },
            { type: 'link', label: 'Contact Us', url: '/site/contact-us' },
            { type: 'link', label: 'Contact Us Alt', url: '/site/contact-us-alt' },
            { type: 'link', label: '404', url: '/site/not-found' },
            { type: 'link', label: 'Terms And Conditions', url: '/site/terms' },
            { type: 'link', label: 'FAQ', url: '/site/faq' },
            { type: 'link', label: 'Components', url: '/site/components' },
            { type: 'link', label: 'Typography', url: '/site/typography' },
        ],
    },

    {
        type: 'button',
        label: 'Валюта',
        children: [
            { type: 'button', label: '€ Euro', data: { type: 'currency', code: 'EUR' } },
            { type: 'button', label: '£ Pound Sterling', data: { type: 'currency', code: 'GBP' } },
            { type: 'button', label: '$ US Dollar', data: { type: 'currency', code: 'USD' } },
            { type: 'button', label: '₽ Russian Ruble', data: { type: 'currency', code: 'RUB' } },
        ],
    },

    {
        type: 'button',
        label: 'Язык',
        children: [
            { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
            { type: 'button', label: 'Russian', data: { type: 'language', locale: 'ru' } },
        ],
    },
];
