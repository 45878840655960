import { ADD_PRODUCTS, ADD_POPULAR_PRODUCTS } from "./shopActionsTypes";
import { RESET_PRODUCTS_STATE } from "./shopActionsTypes";
import { ADD_FILTERS } from './shopActionsTypes';
import { ADD_IMAGES } from "./shopActionsTypes";
import axios from "axios";
function addProducts(state, products) {
    const newProducts = [];
    products.forEach(product => {
        let newProduct = product
        newProduct.price.data = Number(newProduct.price.data)
        newProducts.push(newProduct)
    })
    return {
        ...state,
        products: newProducts
    }
}

function addImages(state, product) {

    const { products } = state
    const itemIndex = products.findIndex((item) => item.uuid === product.uuid)
    state.products = [
        ...state.products.slice(0, itemIndex),
        product,
        ...state.products.slice(itemIndex + 1)
    ]

    return state
}

function addFilters(state, category) {


    // name: category.name,
    let filters = [
        {
            id: category.category_uuid,
            name: category.name,
            type: 'categories',
            options: {
                items: []
            }
        }
    ]

    console.log(category)
    category.child_list.forEach(element => {
        let subCategory = {
            id: element.category_uuid,
            name: element.name,
            type: 'child',
            url: element.category_uuid,
        }
        console.log(subCategory)
        filters[0].options.items.push(subCategory)
    });

    if (typeof category.parent_category_uuid !== 'undefined') {
        let parentCategory = {
            id: category.category_uuid,
            name: 'Назад',
            type: 'parent',
            url: category.parent_category_uuid
        }
        filters[0].options.items.push(parentCategory)
    }
    // let indexFilter = state.breadcrumb.indexOf(filters[0].name)
    // let newBreadcrumb = []
    // if (indexFilter > -1){
    //     console.log("Finded")
    //     console.log(indexFilter)
    //     newBreadcrumb = state.breadcrumb.slice(0, indexFilter + 1)
    //     console.log(newBreadcrumb)
    // } else {
    //     return{
    //         ...state,
    //         filters: filters,
    //         breadcrumb: [...state.breadcrumb, filters[0].name]
    //     }
    //     // newBreadcrumb = state.breadcrumb.push(filters[0].name)
    // }
    return {
        ...state,
        filters: filters,
        // breadcrumb: newBreadcrumb,
    };
}

function addPopularProducts(state, products) {
    // if (products.lenght > 10 ){

    // }
    const newProductsArray = products.slice(0, 15)
    return {
        ...state,
        popularProducts: newProductsArray
    }
}

const initialState = {
    products: [],
    popularProducts: [],
    filters: [],
    breadcrumb: []
};

export default function shopReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCTS:
            return addProducts(state, action.products);
        case ADD_IMAGES:
            return addImages(state, action.product)
        case ADD_FILTERS:
            return addFilters(state, action.category)
        case ADD_POPULAR_PRODUCTS:
            return addPopularProducts(state, action.products)
        case RESET_PRODUCTS_STATE:
            return initialState;
        default:
            return state;
    }
}