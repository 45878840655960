// react
import React, { useState } from 'react';

// third-party
import { Link } from 'react-router-dom';
import Feedback from '../shared/Feedback';

export default function BlockBanner() {
    const [modal, setModal] =useState(false)
    const toggle = () => setModal(!modal);
    return (
        <div className="block block-banner">
            <div className="container">
                <Link to="/" className="block-banner__body">
                    <div
                        className="block-banner__image block-banner__image--desktop"
                        style={{ backgroundImage: 'url("images/banners/banner-1.jpg")' }}
                    />
                    <div
                        className="block-banner__image block-banner__image--mobile"
                        style={{ backgroundImage: 'url("images/banners/banner-1-mobile.jpg")' }}
                    />
                    <div className="block-banner__title">
                        Расходные&nbsp;
                        <br className="block-banner__mobile-br" />
                        материалы
                    </div>
                    <div className="block-banner__text">
                        Для лазерных, электроэрозионных, гидроабразивных станков,<br/> плазменной резки, компрессорного и сварочного оборудования.
                    </div>
                    <div className="block-banner__button">
                        <span onClick={toggle} className="btn btn-sm btn-primary">Подбор инструмента</span>
                        <Feedback open={modal} toogleModal={toggle}/>
                    </div>
                </Link>
            </div>
        </div>
    );
}
