// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItem } from '../../store/cart';
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { wishlistAddItem } from '../../store/wishlist';
import axios from 'axios';

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
    } = props;

    const [imageData, setimageData] = useState([]);



    useEffect(() => {
        let cleanupFunction = false;

        const fetchData = async () => {
            try {
                const response = await axios.get("https://filestorage.okibi-team.ru/file_storage/" + product.miniature.file_uuid);
                if (!cleanupFunction) setimageData(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

        return () => cleanupFunction = true;
    }, [])

    const containerClasses = classNames('product-card', {
        'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
        'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
        'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
        'product-card--layout--list': layout === 'list',
        'product-card--layout--horizontal': layout === 'horizontal',
    });

    let badges = [];
    let image;
    let price;
    let features;
    let availability;
    let productName = product.name.slice(0, 40) + "..."
    // if (product.badges){
    //     if (product.badges.includes('sale')) {
    //         badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
    //     }
    //     if (product.badges.includes('hot')) {
    //         badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
    //     }
    //     if (product.badges.includes('new')) {
    //         badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
    //     }
    // }


    // badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (imageData.data) {
        image = (
            <div className="product-card__image">
                <Link to={`/shop/product/${product.uuid}`}><img src={`data:image/jpeg;base64,${imageData.data}`} alt={imageData.file_name} /></Link>
            </div>
        );
    } else {
        image = (
            <div className="product-card__image">
                <div className='product-card-img-loader'>
                    <div className='btn-loading'></div>
                </div>

                {/* <Link to={`/shop/product/${product.uuid}`}><img src={"/images/products/no_img.jpg"} alt="no_img" /></Link> */}
            </div>
        );
    }
    // if (product.image && product.images.length > 0) {
    //     console.info(product.name);
    //     var images = [];
    //     images.push(product.image);
    //     image = (
    //         <div className="product-card__image">
    //             <Link to={`/shop/product/${product.id}`}><img src={product.images[0]} alt="" /></Link>
    //         </div>
    //     );
    // }

    if (product.price.data > 1) {
        price = (
            <div className="product-card__prices">
                {/* <span className="product-card__new-price"><Currency value={product.price} /></span> */}
                {' '}
                {/* <span className="product-card__old-price"><Currency value={product.compareAtPrice} /></span> */}
                <Currency value={product.price.data} />
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                Цена по запросу
            </div>
        );
    }

    if (product.features && product.features.length) {
        features = (
            <ul className="product-card__features-list">
                {product.features.map((feature, index) => (
                    <li key={index}>{`${feature.name}: ${feature.value}`}</li>
                ))}
            </ul>
        );
    }

    if (product.count > 0) {
        availability = (
            <span className="text-success"> В наличии</span>
        )
    } else {
        availability = (
            <span className="text-danger"> Под заказ</span>
        )
    }

    return (

        <div className={containerClasses}>
            {/* <AsyncAction
                action={() => quickviewOpen(product.uuid)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames('product-card__quickview', {
                            'product-card__quickview--preload': loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            /> */}
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link to={`/shop/product/${product.uuid}`}>{productName}</Link>
                </div>
                {/* <div className="product-card__rating">
                    <Rating value={product.rating} />
                    <div className=" product-card__rating-legend">{`${product.reviews} Reviews`}</div>
                </div> */}
                {features}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    {availability}
                </div>
                {/* {price} */}
                <div className="product-card__buttons">
                    <Link className="btn btn-primary product-card__addtocart" to={`/shop/product/${product.uuid}`}>Подробнее</Link>
                    {/* <button
                        type="button"
                        onClick={<Link to={`/shop/product/${product.uuid}`}></Link>}
                        className="btn btn-primary product-card__addtocart"
                    >
                        Подробнее
                    </button> */}
                    {/* <AsyncAction
                        action={() => cartAddItem(product, [], 1, imageData)}
                        render={({ run, loading }) => (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-primary product-card__addtocart', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    В корзину1
                                </button>
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames('btn btn-secondary product-card__addtocart product-card__addtocart--list', {
                                        'btn-loading': loading,
                                    })}
                                >
                                    В корзину
                                </button>
                            </React.Fragment>
                        )}
                    /> */}
                    {/* <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    <AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    /> */}
                </div>
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCard);
