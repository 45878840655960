// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';

// data stubs
import theme from '../../data/theme';


export default function Footer() {
    const informationLinks = [
        { title: 'О нас', url: '/site/about-us' },
        // { title: 'Доставка', url: '' },
        // { title: 'Политика конфиденциальности', url: '' },
        { title: 'Бренды', url: '/site/catalogs' },
        { title: 'Контакты', url: '/site/contact-us' },
        // { title: 'Новые поступления', url: '' },
    ];

    const accountLinks = [
        { title: 'Местоположение магазина', url: '/site/contact-us' },
        // { title: 'История заказов', url: '' },
        // { title: 'Избранные', url: '' },
        // { title: 'Новостная рассылка', url: '' },
        // { title: 'Специальные предложения', url: '' },
        // { title: 'Филиал', url: '' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-3 col-lg-2">
                            <FooterLinks title="Информация" items={informationLinks} />
                        </div>
                        {/* <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Мой кабинет" items={accountLinks} />
                        </div> */}
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    );
}
